html {
  background: #000;
}

body {
  margin: 0;
}

html,
body,
body > div:first-child,
div#__next,
div#__next > div,
div#__next > div > div {
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
